.horizontal_timeline {
  overflow-x: hidden;

  --passed_color: #000;
  --active_color: #0057B8;
  --pending_color: #000;

  --passed_color-text: #000;
  --active_color-text: #0057B8;
  --pending_color-text: #000;
}

.horizontal_timeline ol {
  width: 100%;
  transition: all 1s;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: auto;
}

.horizontal_timeline ol::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

.horizontal_timeline ol::-webkit-scrollbar-track:horizontal {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.horizontal_timeline ol::-webkit-scrollbar-thumb:horizontal {
  background-color: #999;
  outline: 1px solid slategrey;
  background-clip: padding-box;
}

.horizontal_timeline ol li {
  list-style: none;
  position: relative;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 5px;
  /* min-width: 170px; */
}

.horizontal_timeline ol li span.time {
  display: block;
  font-size: 11px;
  color: var(--pending_color-text);
}

.horizontal_timeline ol li span.content {
  display: block;
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: var(--pending_color-text);
}

.horizontal_timeline ol li:before {
  content: '';
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  border: 2px solid var(--pending_color-text);
  margin: 0 auto 5px auto;
}

.horizontal_timeline ol li:not(:last-child)::after {
  content: '';
  width: calc(100% - 14px);
  height: 7px;
  display: block;
  background: var(--pending_color-text);
  margin: 0;
  margin-top: 1px;
  position: absolute;
  top: 6px; /* Adjust vertical positioning */
  left: calc(50% + 7px);
}

/* Active node start */

.horizontal_timeline ol li.active span.content {
  color: var(--active_color-text);
  font-size: 18px;
}

.horizontal_timeline ol li.active span.time {
  color: var(--active_color-text);
}

.horizontal_timeline ol li.active:before {
  background: var(--active_color);
  border: 2px solid var(--pending_color);
}

/* Active node end */

/* Passed node start */

.horizontal_timeline ol li.passed span.content {
  color: var(--passed_color-text);
}

.horizontal_timeline ol li.passed span.time {
  color: var(--passed_color-text);
}

.horizontal_timeline ol li.passed:before {
  background: #fff;
  border: 2px solid var(--passed_color-text);
}

.horizontal_timeline ol li.passed:after {
  background: var(--passed_color-text);
}

/* Passed node end */




